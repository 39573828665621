















import { Component, Prop, Vue } from 'vue-property-decorator';

import Details from '@/shared/resources/components/details/Details.vue';
import Detail from '@/shared/resources/components/details/Detail.vue';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';

@Component({
  components: {
    Detail,
    Details,
  },
})
export default class ContractorInvoiceData extends Vue {
  /**
   * Props
   */
  @Prop() private contractor!: ContractorCoreModel;
}
